body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #c6ba8e69 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiInputBase-root .Mui-disabled {
  color: rgba(0, 0, 0, 1);
}
.MuiChip-root.Mui-disabled {
  opacity: 0.8 !important;
}
.MuiFormControlLabel-label.Mui-disabled {
  color: rgba(0, 0, 0, 1) !important;
}


/* Estilos para navegadores basados en WebKit (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(250, 226, 104, 0.5);
  border-radius: 10px;
  border: 1px solid #c6ba8e69;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(85, 85, 85, 0.5);
}

::-webkit-scrollbar-horizontal {
  height: 8px;
}