.height600 .rbc-time-content,
.height600 .rbc-time-header-gutter,
.height600 .rbc-row:nth-child(2) {
  display:none;
}

.height600 .rbc-time-header-content {
    background-color: white;
}

.height600 .kinder-selected-date {
  padding:0;
  margin:0; 
  background-color: #99BA82 !important;
}

.height600 .kinder-selected-date .MuiTypography-h5{
  font-size: xx-large !important;
}

.height600 .kinder-selected-date .MuiGrid-grid-xs-true {
  border-radius: 7% 7% 7% 7%;
}

.height600 .kinder-selected-date .MuiGrid-item .MuiTypography-h5 {
  color: #FAE268
}

.height600 .rbc-row.rbc-time-header-cell {
  border-color: #EBAB88;
  background-color: #EBAB88;
  color: #ffffff;
}

.height600 .rbc-header.rbc-today,
.height600 .rbc-day-bg.rbc-today {
  background-color: transparent;
}